import {Chip} from "@material-ui/core";

export const CDTCategories = {
    diagnostic:{
        color:'#0FA3B1',
        displayName: 'Diagnostic'
    },
    preventive:{
        color:'#F75C03',
        displayName: 'Preventive'
    },
    restorative:{
        color:'#F75C03',
        displayName: 'Restorative'
    },
    endodontics:{
        color:'#7E78D2',
        displayName: 'Endo'
    },
    periodontics:{
        color:'#60495A',
        displayName: 'Perio'
    },
    prosthodontics_removable:{
        color:'#403F4C',
        displayName: 'Prosth - Removable'
    },
    maxillofacial_prosthetics:{
        color:'#2C2B3C',
        displayName: 'Maxillofacial'
    },
    dental_implants:{
        color:'#1B2432',
        displayName: 'Implants'
    },
    prosthodontics_fixed:{
        color:'#121420',
        displayName: 'Prosth - Fixed'
    },
    oral_surgery:{
        color:'#210124',
        displayName: 'Oral Surgery'
    },
    orthodontics:{
        color:'#A23B72',
        displayName: 'Ortho'
    },
    adjunctive_dental_services:{
        color:'#324376',
        displayName: 'Adj Servies'
    }
}

export const CDTCategoryChip = ({label})=><Chip
    style={{color:CDTCategories[label]?.color, borderColor:CDTCategories[label]?.color}}
    variant={'outlined'}
    size="small" label={CDTCategories[label]?.displayName} />