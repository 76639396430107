import React from "react";
import {capitalize, Grid} from "@material-ui/core";
import {RenderNonEditableField, RenderSingleSelect, RenderTextField} from "../../../components/form-fields";
import {
    COBRuleOptions,
    CoverageStatusOptions,
    PeriodOptions,
    SeatOrPrepDateOptions,
    YesNoOptions
} from "../../../constants/selectOptions";
import {ContentCard} from "../../../components/cards/ContentCard";
import {MouseOverPopover} from "../../../components/popover";

export const PlanSection = ({
                                officeForm,
                                errors,
                                register,
                                control
                            })=>{
    return <ContentCard title={'Plan'}>
        <Grid
            container
            direction="column"
        >
            <RenderSingleSelect
                label={'Status'}
                options={CoverageStatusOptions} control={control} name={'coverage.status'} defaultValue={officeForm?.coverage?.status}/>
            <RenderSingleSelect
                control={control}
                defaultValue={officeForm?.plan?.period}
                name={'plan.period'}
                options={PeriodOptions}
                label={'Plan Period'}
            />
            <Grid container
                  direction={"row"}>
                <Grid item
                      style={{width:'90%', alignItems:'flex-end'}}
                >
                    <RenderSingleSelect
                        control={control}
                        defaultValue={officeForm?.plan?.missing_tooth_clause}
                        name={'plan.missing_tooth_clause'}
                        options={YesNoOptions}
                        label={'Has Missing Tooth Clause?'}
                    />
                </Grid>
                <Grid style={{width:'10%', paddingTop:'30px'}}>
                    {officeForm?.plan?.expert_tips_missing_tooth_clause && <MouseOverPopover message={officeForm?.plan?.expert_tips_missing_tooth_clause}/>}
                </Grid>
            </Grid>
            <Grid container
                  direction={"row"}>
                <Grid item
                      style={{width:'90%', alignItems:'flex-end'}}
                >
                    <RenderSingleSelect
                        control={control}
                        defaultValue={officeForm?.plan?.seat_or_prep_date}
                        name={'plan.seat_or_prep_date'}
                        options={SeatOrPrepDateOptions}
                        label={'Pays on seat or prep date?'}
                    />
                </Grid>
                <Grid style={{width:'10%', paddingTop:'30px'}}>
                    {officeForm?.plan?.expert_tips_seat_or_prep_date && <MouseOverPopover message={officeForm?.plan?.expert_tips_seat_or_prep_date}/>}
                </Grid>
            </Grid>
            <Grid container
                  direction={"row"}>
                <Grid item
                      style={{width:'90%', alignItems:'flex-end'}}
                >
                    <RenderSingleSelect
                        control={control}
                        defaultValue={officeForm?.plan?.downgrade_on_crowns}
                        name={'plan.downgrade_on_crowns'}
                        options={YesNoOptions}
                        label={'Downgrades on Crowns?'}
                    />
                </Grid>
                <Grid style={{width:'10%', paddingTop:'30px'}}>
                    {officeForm?.plan?.expert_tips_downgrade_crowns && <MouseOverPopover message={officeForm?.plan?.expert_tips_downgrade_crowns}/>}
                </Grid>
            </Grid>
            <Grid container
                  direction={"row"}>
                <Grid item
                      style={{width:'90%', alignItems:'flex-end'}}
                >
                    <RenderSingleSelect
                        control={control}
                        defaultValue={officeForm?.plan?.downgrade_on_fillings}
                        name={'plan.downgrade_on_fillings'}
                        options={YesNoOptions}
                        label={'Downgrades on fillings?'}
                    />
                </Grid>
                <Grid style={{width:'10%', paddingTop:'30px'}}>
                    {officeForm?.plan?.expert_tips_downgrade_fillings && <MouseOverPopover message={officeForm?.plan?.expert_tips_downgrade_fillings}/>}
                </Grid>
            </Grid>
            <Grid container
                  direction={"row"}>
                <Grid item
                      style={{width:'90%', alignItems:'flex-end'}}
                >
                    <RenderSingleSelect
                        control={control}
                        defaultValue={officeForm?.plan?.cob_rule}
                        name={'plan.cob_rule'}
                        options={COBRuleOptions}
                        label={'COB Rule'}
                    />
                </Grid>
                <Grid style={{width:'10%', paddingTop:'30px'}}>
                    {officeForm?.plan?.expert_tips_cob_rule && <MouseOverPopover message={officeForm?.plan?.expert_tips_cob_rule}/>}
                </Grid>
            </Grid>
            <RenderTextField
                name="coverage.effective_date"
                label={'Effective Date'}
                defaultValue={officeForm?.coverage?.effective_date}
                inputRef={register} />
            <RenderTextField
                name="coverage.start_date"
                label={'Start Date'}
                defaultValue={officeForm?.coverage?.start_date}
                inputRef={register} />
            <RenderTextField
                name="coverage.end_date"
                label={'End Date'}
                defaultValue={officeForm?.coverage?.end_date}
                inputRef={register} />
            <RenderTextField
                name="plan.name"
                label={'Name'}
                defaultValue={officeForm?.plan?.name}
                inputRef={register} />
            <RenderTextField
                name="plan.group_number"
                label={'Group Number'}
                defaultValue={officeForm?.plan?.group_number}
                inputRef={register} />
            {officeForm?.plan?.type && <RenderNonEditableField
                label={'Plan Type'}
                name="plan.type"
                value={officeForm?.plan?.type || ''}
                control={control} />}

        </Grid>
    </ContentCard>
}