import {PayersAPIContextProvider, usePayerAPI} from "../../features/Payers/PayersAPIContext";
import {PayerSearchAutocomplete} from "../../features/Payers/PayerSearchAutocomplete";
import {Button, Checkbox, FormControlLabel, Grid, Paper} from "@material-ui/core";
import {PageMargins} from "../../layout/PageMargins";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {pruneEmpty} from "../../utils/api";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import {Controller} from "react-hook-form";
import { Alert, AlertTitle } from '@material-ui/lab';
import {useEBData} from "../../features/EB/EBDataContext";
import {gql, useMutation} from "@apollo/client";
import './ebhtml-request-page.css'
import {RenderDatePicker, RenderSingleSelect, RenderTextField} from "../../components/form-fields";
import {
    CIGNA_NETWORKS,
    CignaNetworkOptions,
    DD_NETWORKS,
    DDNetworkOptions, GUARDIAN_NETWORKS,
    GuardianNetworkOptions, PayerOptions, Simple2NetworksOptions
} from "../../constants/selectOptions";
import {Loading} from "../../components/loading";

const CALL_EB = gql`
    mutation createOfficeForm($input: OfficeFormInputType) {
        createOfficeForm(input: $input) {
            onederfulId
            is_custom
            provider {
                first_name
                last_name
                organization_name
                tax_id
                npi
            }
            patient {
                name
                first_name
                middle_name
                last_name
                dob
                member_id
                address {
                    street1
                    street2
                    city
                    zip
                    state
                }
                gender
                relationship
                is_subscriber
                metadata {
                    type
                    value
                }
                age
            }
            coverage {
                effective_date
                status
                start_date
                end_date
            }
            payer {
                id
                name
                phone_number
                website
                notes
            }
            plan {
                name
                number
                type
                group_number
                group_name
                state
                period
                has_family_coverage
                basis_of_payment
                cob_rule
                missing_tooth_clause
                seat_or_prep_date
                downgrade_on_crowns
                downgrade_on_fillings
                expert_tips_cob_rule
                expert_tips_missing_tooth_clause
                expert_tips_seat_or_prep_date
                expert_tips_downgrade_crowns
                expert_tips_downgrade_fillings
            }
            procedure_codes {
                procedure_code
                category
                subcategory
                synonyms
                nomenclature
                expert_tips
                percent
                history {
                    category
                    service_type
                    procedure_code
                    service_date
                    tooth_code
                    tooth_surfaces
                    type
                    value
                }
                notes
                limitation_applies_to
                disclaimers
                limitations {
                    frequency
                    age_high_value
                    age_low_value
                    frequency_component {
                        quantity
                        quantity_qualifier
                        time_period_value
                        time_period_qualifier
                    }
                    remaining_eligibility {
                        quantity
                        quantity_qualifier
                        time_period_value
                        time_period_qualifier
                    }
                }
                is_age_limit_needed
                is_only_last_service_date
                should_display_limitation_applies_to
                is_not_covered
                is_field_matched_by_procedure_code
                does_payer_return_percent
                does_payer_return_history
                does_payer_return_frequency
                does_payer_return_limitations_applies_to
                does_payer_return_age_limit
                does_payer_return_remaining_eligibility
                additional_questions {
                    question
                    value
                    ui_type
                    options
                }
            }
            effective_dates {
                network
                category
                service_type
                effective_date
                is_a_waiting_period
            }
            network
            payer_specific_description
            maximum
            maximum_remaining
            ortho_maximum
            ortho_maximum_remaining
            expert_tips_ortho_age_limit
            ortho_coinsurance
            ortho_age_limit
            ortho_deductible
            ortho_deductible_remaining
            expert_tips_ortho_info
            individual_deductible
            individual_deductible_remaining
            family_deductible
            family_deductible_remaining
            expert_tips_deductible_apply_to_diagnostic
            should_apply_deductible_to_diagnostic
            should_apply_deductible_to_preventive
            expert_tips_waiting_period
            does_maximum_apply_to_preventive
            html
        }
    }
`

function normalizeTaxId(taxId){
    if(taxId && taxId.includes('-')){
        return taxId.split('-').join('')
    }
    return taxId
}

const getDDCheck = (selectedPayerId) =>selectedPayerId === 'DD_CALIFORNIA' || selectedPayerId === 'DD_PENNSYLVANIA' || selectedPayerId==='DD_GEORGIA' || selectedPayerId==='DD_NEW_YORK' || selectedPayerId ==='DD_NEW_JERSEY'


/*--------------------------------------------
For multiple tiered payers
--------------------------------------------*/
export function normalizeProviderRequest(request){
    const provider = request?.provider
    const selectedPayerId = request?.payer?.id
    if(getDDCheck(selectedPayerId)){
        if(provider?.network === DD_NETWORKS.PPO){
            return {
                ...provider,
                network: 'in_network',
                payer_specific_description: DD_NETWORKS.PPO,
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network===DD_NETWORKS.PREMIER){
            return {
                ...provider,
                network: 'in_network',
                payer_specific_description: DD_NETWORKS.PREMIER,
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network === DD_NETWORKS.OON){
            return {
                ...provider,
                network: 'out_of_network',
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
    }
    if(selectedPayerId === 'CIGNA'){
        if(provider?.network === CIGNA_NETWORKS.CIGNA_DPPO_ADVANTAGE){
            return {
                ...provider,
                network: 'in_network',
                payer_specific_description: CIGNA_NETWORKS.CIGNA_DPPO_ADVANTAGE,
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network===CIGNA_NETWORKS.CIGNA_DPPO){
            return {
                ...provider,
                network: 'in_network',
                payer_specific_description: CIGNA_NETWORKS.CIGNA_DPPO,
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network === CIGNA_NETWORKS.out_of_network){
            return {
                ...provider,
                network: 'out_of_network',
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
    }
    if(selectedPayerId === 'GUARDIAN'){
        if(provider?.network === GUARDIAN_NETWORKS.in_network){
            return {
                ...provider,
                network: 'in_network',
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network === GUARDIAN_NETWORKS.oon){
            return {
                ...provider,
                network: 'out_of_network',
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network === GUARDIAN_NETWORKS.DG_GOLD){
            return {
                ...provider,
                network: 'in_network',
                payer_specific_description:GUARDIAN_NETWORKS.DG_GOLD,
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network === GUARDIAN_NETWORKS.DG_PLATINUM){
            return {
                ...provider,
                network: 'in_network',
                payer_specific_description:GUARDIAN_NETWORKS.DG_PLATINUM,
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
        if(provider?.network === GUARDIAN_NETWORKS.DG_SILVER){
            return {
                ...provider,
                network: 'in_network',
                payer_specific_description: GUARDIAN_NETWORKS.DG_SILVER,
                tax_id: normalizeTaxId(provider?.tax_id)
            }
        }
    }
    return {
        ...provider
    }
}

function preprocessRequest(request){
    return {
        ...request,
        provider: normalizeProviderRequest(request),
        payer: {
            id: request?.payer?.id
        }
    }
}
export const EBHTMLRequestPage = () => {
    // @ts-ignore
    const {ebRequest, setEBRequest, ebResponse, setEBResponse} = useEBData()
    const [callEB, { loading: mutationLoading, error: mutationError }] = useMutation(CALL_EB);
    const history = useHistory();
    const [errors, setErrors] = useState([])
    const [shouldShowDependent, setShouldShowDependent] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const { handleSubmit, register, control, reset, watch, getValues, setValue } = useForm({});

    const watchFields = watch("payer");
    const selectedPayerId = getValues('payer')?.id
    const onClear = ()=>{
        reset()
        setErrors([])
    }

    const onSubmit = async(r, e)=>{
        const normalizedRequest = pruneEmpty(preprocessRequest(r))
        setEBRequest(normalizedRequest)
        setErrors([])
        if(!r?.payer){
            // @ts-ignore
            return setErrors([{message:'Please select payer from dropdown'}])
        }
        setIsFetching(true)
        try{
            const {data, errors:graphqlErrors} = await callEB({
                variables:{
                    input:normalizedRequest
                }
            })
            setEBResponse(data?.createOfficeForm)
            const onederfulId = data?.createOfficeForm?.onederfulId
            history.push(`/office-form/${onederfulId}`)
            setIsFetching(false)
        }
        catch(e){
            setIsFetching(false)
            // @ts-ignore
            setErrors(_.first(e?.graphQLErrors)?.errors)
        }
    }

    const getProviderNetworkOptions = ()=>{
        if(getDDCheck(selectedPayerId)){
            return <RenderSingleSelect
                control={control}
                defaultValue={DD_NETWORKS.PPO}
                name={'provider.network'}
                options={DDNetworkOptions}
                label={'Network'}
            />
        }
        if(selectedPayerId === 'GUARDIAN'){
            return <div>
                <RenderSingleSelect
                    control={control}
                    defaultValue={'in_network'}
                    name={'provider.network'}
                    options={GuardianNetworkOptions}
                    label={'Network'}
                /></div>
        }

        if(selectedPayerId === 'CIGNA'){
            return <div>
                <RenderSingleSelect
                    control={control}
                    defaultValue={CIGNA_NETWORKS.CIGNA_DPPO_ADVANTAGE}
                    name={'provider.network'}
                    options={CignaNetworkOptions}
                    label={'Network'}
                /></div>
        }

        return <div>
            <RenderSingleSelect
                control={control}
                defaultValue={'in_network'}
                name={'provider.network'}
                options={Simple2NetworksOptions}
                label={'Network'}
            /></div>

    }

    const renderErrorAlert =()=>{
        // @ts-ignore
        const errorMessage = _.first(errors)?.message || _.first(errors)?.reason
        return Array.isArray(errors) && errors.length > 0 &&
            <Alert severity="error" style={{marginTop:'30px'}}>{errorMessage}</Alert>
    }
    return (
        <PayersAPIContextProvider>
            <PageMargins>
                <Paper elevation={3}>
                    <form onSubmit={handleSubmit(onSubmit)} className={'request-form'}>
                        <div className={'request-form-label'}>Eligibility and Benefits Request Form</div>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="baseline"
                            spacing={3}
                        >
                            <Grid item xs={12} md={3}>
                                <RenderTextField
                                    name="subscriber.first_name"
                                    label={'Subscriber First Name'}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RenderTextField
                                    name="subscriber.last_name"
                                    label={'Subscriber Last Name'}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RenderTextField
                                    name="subscriber.member_id"
                                    label={'Subscriber Member ID'}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RenderDatePicker
                                    name={"subscriber.dob"}
                                    label={"Subscriber DOB"}
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RenderTextField
                                    name={"subscriber.group_number"}
                                    label={"Group Number"}
                                    inputRef={register}
                                />
                            </Grid>
                            {shouldShowDependent ? <>
                                <Grid item xs={12} md={3}>
                                    <RenderTextField
                                        name="dependent.first_name"
                                        label={'Dependent First Name'}
                                        inputRef={register} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <RenderTextField
                                        name="dependent.last_name"
                                        label={'Dependent Last Name'}
                                        inputRef={register} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <RenderDatePicker
                                        name={"dependent.dob"}
                                        label={"Dependent DOB"}
                                        control={control}
                                    />
                                </Grid>
                            </>:null}
                            <Grid item xs={12} md={3}>
                                <RenderTextField
                                    name="provider.npi"
                                    label={'Provider NPI'}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RenderTextField
                                    name="provider.tax_id"
                                    label={'Provider Tax ID (TIN)'}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {getProviderNetworkOptions()}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    control={control}
                                    name="payer"
                                    render={({
                                                 onChange
                                             }) => (
                                        <PayerSearchAutocomplete
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              direction="row"
                              justify="space-between"
                        >
                            <Grid item xs={12} md={8}>
                                <FormControlLabel
                                    value="top"
                                    control={<Checkbox color="primary" />}
                                    label="Search For Dependent"
                                    labelPlacement="start"
                                    onChange={()=>setShouldShowDependent(!shouldShowDependent)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button disabled={isFetching} onClick={onClear} color={'default'} variant={'contained'}>Clear</Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button disabled={isFetching} type="submit" color={'primary'} variant={'contained'}>Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                {isFetching && <Loading/>}
                {renderErrorAlert()}
            </PageMargins>
        </PayersAPIContextProvider>
    );
}




