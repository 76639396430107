import {Controller} from "react-hook-form";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import React, {useState} from "react";
import Select, {createFilter} from "react-select";
import {cdtCodes} from "../../constants/cdtCodes";
import {FixedSizeList as List} from "react-window";
import _ from 'lodash'
import {TagInput} from "../tagify/TagInput";
import './form-fields.css'
import {TextField} from "@material-ui/core";
class MenuList extends React.Component {
    render() {
        const height = 35;
        const {options, children, maxHeight, getValue} = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({index, style}) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}

export const RenderNonEditableField = ({control, name, value, label})=>{
    return <Controller
        defaultValue={value}
        control={control}
        name={name}
        render={()=>(
            <div className={'non-editable-field'} style={{margin:'5px 0'}}>
                <label>{label}</label>
                <div>{value}</div>
            </div>
        )}
    />
}

export const RenderInvisibleField = ({control, name, value})=>{
    return <Controller
        defaultValue={value}
        control={control}
        name={name}
        render={()=>(
            <div>{value}</div>
        )}
    />
}


export const RenderNonEditableLink = ({control, name, value, label})=>{
    return <Controller
        control={control}
        defaultValue={value}
        name={name}
        render={()=>(
            <div className={'non-editable-field'} style={{margin:'5px 0'}}>
                <label>{label}</label>
                <div><a target={'__blank'} href={value}>{value}</a></div>
            </div>
        )}
    />
}


function KeyboardDatePickerExample({onChange, label, value}) {
    const inputDate = moment(value);
    const [inputValue, setInputValue] = useState(
       inputDate.isValid() ?  moment(value).format("MM/DD/YYYY"): null
    );
    const onDateChange = (date, value) => {
        setInputValue(value);
        onChange(moment(date).format('MM/DD/YYYY'))
    };



    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                autoOk={true}
                label={label}
                showTodayButton={true}
                format="MM/DD/YYYY"
                value={value}
                inputValue={inputValue}
                onChange={onDateChange}
                // rifmFormatter={dateFormatter}
            />
        </MuiPickersUtilsProvider>
    );
}


export const RenderDatePicker = ({
                                     control,
                                     name,
                                     defaultValue = null,
                                     label,
                                 })=>{
    return  <Controller
        control={control}
        name={name}
        // here the magic happens
        initialFocusedDate={null}
        defaultValue={defaultValue}
        render={({onChange, value}) => (
            <KeyboardDatePickerExample onChange={onChange} label={label} value={value}/>
            // <MuiPickersUtilsProvider
            //     utils={MomentUtils}
            //     className="input"
            //     placeholderText="Select date"
            // >
            //     <KeyboardDatePicker
            //         InputLabelProps={{style: {fontSize: 12}, shrink:true}}
            //         style={{margin:'5px 0'}}
            //         disableToolbar
            //         autoOk={true}
            //         label={label}
            //         // inputVariant={'outlined'}
            //         variant={'inline'}
            //         format={'MM/DD/YYYY'}
            //         margin={'normal'}
            //         value={value}
            //         onChange={(e) => onChange(e)}
            //         rifmFormatter={(str)=>{return str}}
            //     />
            // </MuiPickersUtilsProvider>
        )}
    />
}

export const RenderMultiSelect = ({
                                      control,
                                      name,
                                      options,
                                      defaultValue,
                                      label,
                                  })=>{
    return  <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
                     onChange, onBlur, value, name, ref
                 })=><>
            <div className={'select-label'} style={{margin:'5px 0'}}>{label}</div>
            <Select
                isMulti
                filterOption={createFilter({
                    ignoreAccents:false
                })}
                onChange={e=>{
                    onChange(Array.isArray(e) && e.map(a=>a.value))
                }}
                defaultValue={Array.isArray(defaultValue) && defaultValue.map(a=>({value: a, label:a}))}
                components={{MenuList}}
                options={options}/>
        </>}
    />

}

export const RenderSingleSelect = ({
                                       name,
                                       control,
                                       options = [],
                                       defaultValue = '',
                                       label
                                   })=>{


    return  <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
                     onChange, onBlur, value, name, ref
                 })=><>
            <div className={'select-label'} style={{margin:'5px 0'}}>{label}</div>
            <Select
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                filterOption={createFilter({
                    ignoreAccents:false
                })}
                onChange={e=>{
                    onChange(e.value)
                }}
                defaultValue={_.find(options, a=>a.value===defaultValue)}
                options={options}/>
        </>}
    />
}

export const RenderTagInput = ({
                                   name,
                                   control,
                                   options = [],
                                   label,
                                   defaultValue = null
                               })=>{
    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
                     onChange,
                 })=><div  style={{margin:'5px 0'}}>
            <TagInput
                onChange={onChange}
                defaultValue={defaultValue}
            />
        </div>}
    />
}

export const RenderTextField = (props)=>{
    return  <TextField
        style={{margin:'5px 0'}}
        {...props}
        InputLabelProps={{style: {fontSize: 12}}}
    />
}