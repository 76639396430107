import './App.css';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import {OfficeForm} from "./pages/office-form/OfficeForm";
import { ThemeProvider } from '@material-ui/styles';
import {customTheme} from "./utils/theme";
import {Loading} from "./components/loading";
import {Layout} from "./layout";
import {PageMargins} from "./layout/PageMargins";
import {PastSearch} from "./pages/past-search/PastSearch";
import {EBHTMLRequestPage} from "./pages/EBHTMLRequestPage";
import React from "react";
import {ROUTE_ENUM} from "./config/routes";
import {EBDataProvider} from "./features/EB/EBDataContext";

const LandingPage = (props)=>{
  const {isAuthenticated } = useAuth0();
  if(!isAuthenticated){
    return <header className="App-header">
      PatientYes Smart Form
    </header>
  }
  return <PastSearch {...props}/>
}

function App() {
  const {isLoading} = useAuth0();
  if(isLoading){
    return <Layout>
      <PageMargins>
        <Loading/>
      </PageMargins>
    </Layout>
  }
  return (
      <Layout>
        <Switch>
          <ThemeProvider theme={customTheme}>
            <Route path={"/"} exact component={LandingPage}/>
            <EBDataProvider>
              <ProtectedRoute path={ROUTE_ENUM.PAST_SEARCH_VIEW} component={LandingPage}/>
              <ProtectedRoute path={ROUTE_ENUM.EB_HTML_REQUEST_VIEW} component={EBHTMLRequestPage}/>
              <ProtectedRoute path={ROUTE_ENUM.OFFICE_FORM_VIEW} component={OfficeForm}/>
            </EBDataProvider>
          </ThemeProvider>
        </Switch>
      </Layout>
  );
}

export default App;
