import { createTheme } from '@material-ui/core/styles';

export const customTheme = createTheme({
    palette: {
        primary: {
            main: '#26a69a',
        },
        secondary: {
            main: '#2672A6',
        }
    },
});