import React, {useState, useEffect, useCallback, useRef} from "react"
// import Tags from "./tagify/react.tagify"
import Tags from "@yaireo/tagify/dist/react.tagify"
import '@yaireo/tagify/dist/tagify.css'

// callbacks props (for this demo, the same callback reference is assigned to every event type)
const tagifyCallbacks = {
    // add: callback,
    // remove: callback,
    // input: callback,
    // edit: callback,
    // invalid: callback,
    // click: callback,
    // keydown: callback,
    // focus: callback,
    // blur: callback,
    // "edit:input": callback,
    // "edit:updated": callback,
    // "edit:start": callback,
    // "edit:keydown": callback,
    // "dropdown:show": callback,
    // "dropdown:hide": callback,
    // "dropdown:select": callback
}

// this is an example React component which implemenets Tagify within
// itself. This example is a bit elaborate, to demonstrate what's possible.
export const TagInput = ({onChange, value, defaultValue, label}) => {
    const tagifyRef = useRef()
    // just a name I made up for allowing dynamic changes for tagify settings on this component
    const [tagifySettings, setTagifySettings] = useState([])
    const [tagifyProps, setTagifyProps] = useState({})
    const [val, setVal] = useState((Array.isArray(defaultValue) && defaultValue.map(a=>a.service_date)) || [])

    // merged tagify settings (static & dynamic)
    const settings = {
        // blacklist: ["xxx", "yyy", "zzz"],
        maxTags: 20,
        //backspace: "edit",
        placeholder: 'MM/DD/YYYY',
        dropdown: {
            enabled: 0 // a;ways show suggestions dropdown
        },
        callbacks: tagifyCallbacks
    }

    // const handleChange = useCallback((e) => {
    //     e.persist()
    //
    //     // onChange(e.target.value?.map(a=>a.value))
    // }, [])

    const handleChange = (e)=>{
        e.preventDefault()
        try{
            if(Array.isArray(JSON.parse(e.target.value))){
                onChange(JSON.parse(e.target.value)?.map(a=>({
                    service_date: a.value
                })))
            }
        }
        catch(e){

        }
    }

    // // access Tagify internal methods example:
    // const clearAll = () => {
    //     tagifyRef.current && tagifyRef.current.removeAllTags()
    // }

    return (
        <Tags
            tagifyRef={tagifyRef}
            settings={settings}
            value={val}
            {...tagifyProps}
            onChange={handleChange}
        />
    )
}


