import {gql} from "@apollo/client";

export const GET_OFFICE_FORM = gql`
    # Write your query or mutation here
    query getOfficeForm(
        $onederfulId: String
        $isBlank: Boolean
        $fetchCustom:Boolean
    ) {
        getOfficeForm(
            onederfulId: $onederfulId
            isBlank: $isBlank
            fetchCustom:$fetchCustom
        ) {
            is_custom
            html(onederfulId: $onederfulId)
            network
            payer_specific_description
            provider {
                ...providerFragment
            }
            patient {
                ...patientFragment
            }
            coverage {
                ...coverageFragment
            }
            payer {
                ...payerFragment
            }
            plan {
                ...planFragment
            }
            maximum
            maximum_remaining
            ortho_age_limit
            ortho_deductible
            ortho_coinsurance
            ortho_deductible_remaining
            ortho_maximum
            ortho_maximum_remaining
            individual_deductible
            individual_deductible_remaining
            family_deductible
            family_deductible_remaining
            should_apply_deductible_to_diagnostic
            should_apply_deductible_to_preventive
            does_maximum_apply_to_preventive
            procedure_codes {
                ...procedureCodeFragment
            }
            effective_dates {
                ...effectiveDateFragment
            }
            expert_tips_waiting_period
            onederfulId
        }
    }

    fragment providerFragment on EBProviderType {
        first_name
        last_name
        npi
        tax_id
    }

    fragment patientFragment on EBPatientType {
        first_name
        last_name
        dob
        member_id
        relationship
        is_subscriber
        age
    }

    fragment coverageFragment on EBCoverageType {
        status
        effective_date
        start_date
        end_date
    }

    fragment payerFragment on OfficeFormPayerMetadata {
        notes
        id
        phone_number
        website
        name
    }
    fragment planFragment on OfficeFormPlanType {
        name
        type
        group_number
        has_family_coverage
        period
        has_family_coverage
        basis_of_payment
        cob_rule
        missing_tooth_clause
        seat_or_prep_date
        downgrade_on_crowns
        downgrade_on_fillings
        expert_tips_cob_rule
        expert_tips_missing_tooth_clause
        expert_tips_seat_or_prep_date
        expert_tips_downgrade_crowns
        expert_tips_downgrade_fillings
    }

    fragment effectiveDateFragment on EBEffectivePeriod {
        network
        category
        service_type
        effective_date
        is_a_waiting_period
    }

    fragment procedureCodeFragment on OfficeFormProcedureCodeType {
        procedure_code
        expert_tips
        is_not_covered
        category
        subcategory
        synonyms
        nomenclature
        percent
        history {
            service_date
        }
        notes
        disclaimers
        is_age_limit_needed
        should_display_limitation_applies_to
        limitation_applies_to
        limitations {
            ...limitationFragment
        }
        does_payer_return_percent
        does_payer_return_history
        does_payer_return_age_limit
        does_payer_return_frequency
        does_payer_return_limitations_applies_to
    }

    fragment limitationFragment on ProcedureCodeLimitationType {
        frequency
        frequency_component {
            time_period_value
            time_period_qualifier
            quantity
        }
        age_low_value
        age_high_value
    }

`;
