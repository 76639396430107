export const adaCategories = {
    diagnostic: 'Diagnostic (D0100-D0999)',
    preventive: 'Preventive (D1000-D1999)',
    restorative: 'Restorative (D2000-D2999)',
    endodontics: 'Endodontics (D3000-D3999)',
    periodontics: 'Periodontics (D4000-D4999)',
    prosthodontics_removable: 'Prosthodontics - removable (D5000-5899)',
    maxillofacial_prosthetics: 'Maxillofacial Prosthetics (D5900-D5999)',
    implant_services: 'Implant Services (D6000-D6199)',
    prosthodontics_fixed: 'Prosthodontics - fixed (D6200-D6999)',
    oral_maxillofacial_surgery: 'Oral & Maxillofacial Surgery (D7000-D7999)',
    orthodontics: 'Orthodontics (D8000-D8999)',
    adjunctive_services: 'Adjunctive Services (D9000-D9999)'
}