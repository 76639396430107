import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0WithHistory} from "./auth/Auth0WithHistory";
import {AuthorizedApolloProvider} from "./auth/AuthorizedApolloProvider";
import {BrowserRouter} from 'react-router-dom'

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0WithHistory>
                <AuthorizedApolloProvider>
                    <App />
                </AuthorizedApolloProvider>
            </Auth0WithHistory>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export {API_ENDPOINT} from "./config/endpoints";