import React from "react";
import {Grid, TextField} from "@material-ui/core";
import {RenderSingleSelect, RenderTextField} from "../../../components/form-fields";
import {YesNoOptions} from "../../../constants/selectOptions";
import {ContentCard} from "../../../components/cards/ContentCard";

export const DeductibleSection = ({
                                      officeForm,
                                      errors,
                                      register,
                                      control
                                  })=>{
    return <ContentCard title={'Deductible'}>
        <Grid
            container
            direction="column"
        >
            <RenderSingleSelect
                control={control}
                name={'should_apply_deductible_to_diagnostic'}
                defaultValue={officeForm?.should_apply_deductible_to_diagnostic}
                options={YesNoOptions}
                label={'Should deductible apply to diagnostic?'}
            />
            <RenderSingleSelect
                control={control}
                name={'should_apply_deductible_to_preventive'}
                defaultValue={officeForm?.should_apply_deductible_to_preventive}
                options={YesNoOptions}
                label={'Should deductible apply to preventive?'}
            />
            <RenderTextField
                name="individual_deductible"
                label={'Individual Deductible'}
                defaultValue={officeForm?.individual_deductible}
                inputRef={register} />
            <RenderTextField
                name="individual_deductible_remaining"
                label={'Individual Deductible Remaining'}
                defaultValue={officeForm?.individual_deductible_remaining}
                inputRef={register} />
            <RenderTextField
                name="family_deductible"
                label={'Family Deductible'}
                defaultValue={officeForm?.family_deductible}
                inputRef={register} />
            <RenderTextField
                name="family_deductible_remaining"
                label={'Family Deductible Remaining'}
                defaultValue={officeForm?.family_deductible_remaining}
                inputRef={register} />
        </Grid>
    </ContentCard>
}