import React, { useContext, useState, useEffect, createContext } from "react";


const EBDataContext = createContext({});


function EBDataProvider({ children }) {
    // Initialize state
    const [ebRequest, setEBRequest] = useState({});
    const [ebResponse, setEBResponse]= useState({});
    const [isLoading, setIsLoading] = useState(true);

    // Fetch data
    useEffect(() => {

    }, []);

    return (
        <EBDataContext.Provider value={{
            ebRequest,
            setEBRequest,
            ebResponse,
            setEBResponse,
            isLoading,
        }}>
            {children}
        </EBDataContext.Provider>
    );
}

function useEBData() {
    const context = useContext(EBDataContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
}

export {EBDataProvider, useEBData}