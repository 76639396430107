import React, {useState} from "react";
import SearchBar from "material-ui-search-bar";
import Fuse from 'fuse.js'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import _ from 'lodash'
import {Box, Grid, TextField} from "@material-ui/core";
import {
    RenderInvisibleField,
    RenderMultiSelect,
    RenderNonEditableField,
    RenderSingleSelect,
    RenderTagInput,
    RenderTextField
} from "../../../components/form-fields";
import {CDTOptions, TimePeriodOptions, YesNoOptions} from "../../../constants/selectOptions";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {CDTCategoryChip} from "../../../components/chips/CDTCategoryChip";
import {MouseOverPopover} from "../../../components/popover";

/*--------------------------------------------
Fuzzy match searching
--------------------------------------------*/

function Row(props){
    const {row, displayStyle, idx, register, control} = props
    const [open, setOpen] = React.useState(false);
    const isNotCovered = row?.is_not_covered

    const tableRowStyle = isNotCovered ? {
            ...displayStyle,
            backgroundColor:'rgba(255,0,0,0.25)'
    } : {
        ...displayStyle,
    }

    return (
        <React.Fragment>
            <TableRow style={tableRowStyle} key={idx}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row?.expert_tips && <MouseOverPopover message={row?.expert_tips}/>}
                </TableCell>
                <TableCell component="th" scope="row">
                    <RenderInvisibleField
                        control={control}
                        value={row?.procedure_code}
                        name={`procedure_codes[${idx}].procedure_code`}
                    />
                    {isNotCovered ? <p>NOT COVERED</p>:null}
                </TableCell>
                {/*<TableCell component="th" scope="row">*/}
                {/*    {row.nomenclature}*/}
                {/*</TableCell>*/}
                <TableCell component="th" scope="row">
                    <Box flexDirection={'column'}>
                        <CDTCategoryChip label={row?.category}/>
                        <div>{_.first(row?.synonyms)}</div>
                    </Box>
                </TableCell>
                <TableCell>
                    <RenderTextField
                        name={`procedure_codes[${idx}].percent`}
                        label={'Benefit %'}
                        defaultValue={row?.percent}
                        inputRef={register} />
                </TableCell>
                <TableCell component="th" scope="row">
                    {Array.isArray(row?.limitations) && row?.limitations.map((limitation, limitationIdx)=>{
                        return (
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems={'flex-end'}
                                justify={'space-around'}
                            >
                                <Grid item xs={2}>
                                    <RenderTextField
                                        name={`procedure_codes[${idx}].limitations[${limitationIdx}].frequency_component.quantity`}
                                        defaultValue={limitation?.frequency_component?.quantity}
                                        inputRef={register} />
                                </Grid>
                                <Grid item xs={1}>
                                    x
                                </Grid>
                                <Grid item xs={2}>
                                    <RenderTextField
                                        name={`procedure_codes[${idx}].limitations[${limitationIdx}].frequency_component.time_period_value`}
                                        defaultValue={limitation?.frequency_component?.time_period_value}
                                        inputRef={register} />
                                </Grid>
                                <Grid item xs={7} style={{minWidth:'75px'}}>
                                    <RenderSingleSelect
                                        control={control}
                                        name={`procedure_codes[${idx}].limitations[${limitationIdx}].frequency_component.time_period_qualifier`}
                                        defaultValue={limitation?.frequency_component?.time_period_qualifier}
                                        options={TimePeriodOptions}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                    {!row?.limitations && <Grid
                        container
                        direction="row"
                        spacing={2}
                        alignItems={'flex-end'}
                        justify={'space-around'}
                    >
                        <Grid item xs={2}>
                            <RenderTextField
                                name={`procedure_codes[${idx}].limitations[0].frequency_component.quantity`}
                                defaultValue={null}
                                inputRef={register} />
                        </Grid>
                        <Grid item xs={1}>
                            x
                        </Grid>
                        <Grid item xs={2}>
                            <RenderTextField
                                name={`procedure_codes[${idx}].limitations[0].frequency_component.time_period_value`}
                                defaultValue={null}
                                inputRef={register} />
                        </Grid>
                        <Grid item xs={7} style={{minWidth:'75px'}}>
                            <RenderSingleSelect
                                control={control}
                                name={`procedure_codes[${idx}].limitations[0].frequency_component.time_period_qualifier`}
                                defaultValue={null}
                                options={TimePeriodOptions}
                            />
                        </Grid>
                    </Grid>}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row?.is_age_limit_needed && !row?.limitations ? <><RenderTextField
                        name={`procedure_codes[${idx}].limitations[0].age_low_value`}
                        inputRef={register}
                        label={'Age Low'}
                        style={{margin: '0 5px'}}
                        InputLabelProps={{style: {fontSize: 14}}}
                    />
                        <RenderTextField
                            name={`procedure_codes[${idx}].limitations[0].age_high_value`}
                            inputRef={register}
                            label={'Age High'}
                            style={{margin: '0 5px'}}
                            InputLabelProps={{style: {fontSize: 14}}}
                        /></>:null}

                    {Array.isArray(row?.limitations) && row?.limitations.map((limitation, limitationIdx)=>{
                        return (
                            <Box display={"flex"} flexDirection={'row'}>
                                {row?.is_age_limit_needed ? <><RenderTextField
                                    name={`procedure_codes[${idx}].limitations[${limitationIdx}].age_low_value`}
                                    defaultValue={limitation?.age_low_value}
                                    inputRef={register}
                                    label={'Age Low'}
                                    style={{margin: '0 5px'}}
                                    InputLabelProps={{style: {fontSize: 14}}}
                                />
                                    <RenderTextField
                                        name={`procedure_codes[${idx}].limitations[${limitationIdx}].age_high_value`}
                                        defaultValue={limitation?.age_high_value}
                                        inputRef={register}
                                        label={'Age High'}
                                        style={{margin: '0 5px'}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                    /></> : null}

                            </Box>
                        )
                    })}
                </TableCell>
                <TableCell component="th" scope="row">
                    <RenderTagInput
                        control={control}
                        label={'MM/DD/YYYY'}
                        name={`procedure_codes[${idx}].history`}
                        defaultValue={(Array.isArray(row?.history) && row?.history?.map(a=>({service_date:a?.service_date}))) || null}
                    />
                </TableCell>
                <TableCell component="th" scope="row">
                    {row?.should_display_limitation_applies_to ? <RenderMultiSelect
                        control={control}
                        name={`procedure_codes[${idx}].limitation_applies_to`}
                        options={CDTOptions}
                    /> : null}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={12} style={{padding:'0px'}}>
                    <Collapse in={open} timeout="auto">
                        <Grid container style={{padding:'10px'}}>
                            <Grid item xs={6}>
                                <RenderTextField
                                    fullWidth={true}
                                    multiline
                                    rows={3}
                                    name={`procedure_codes[${idx}].notes`}
                                    defaultValue={row?.notes}
                                    inputRef={register}
                                    label={'Notes'}
                                    variant={'outlined'}
                                />
                                <ul>
                                    {Array.isArray(row?.disclaimers) && row?.disclaimers?.map(a=><li>{a}</li>)}
                                </ul>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


function DenseTable({
                        data,
                        errors,
                        register,
                        control,
                        shouldShowRow
                    }) {
    const useStyles = makeStyles({
        tableContainer:{
            // width:1500
        },
        table: {
            minWidth: 1200,
            // width: 1500,
            // tableLayout:'auto',
            wordWrap:'break-word'
        },
    });
    const classes = useStyles();
    if(!Array.isArray(data)) return null;

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell/>
                        <TableCell>Procedure Code</TableCell>
                        {/*<TableCell>Nomenlature</TableCell>*/}
                        <TableCell>
                            <Box flexDirection={'column'}>
                                <div>Category</div>
                                <div>Common Name</div>
                            </Box>
                        </TableCell>
                        <TableCell width={'75px'}>Benefit %</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell>Age Limit</TableCell>
                        <TableCell>History</TableCell>
                        <TableCell width={'175px'}>Shares Frequency with</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, idx) => {
                        let displayStyle = {
                            display:'none'
                        }
                        if(Array.isArray(shouldShowRow)){
                            if(shouldShowRow.length === 0){
                                displayStyle={}
                            }
                            if(shouldShowRow.includes(row.procedure_code)){
                                displayStyle={}
                            }
                        }
                        return (
                            <Row row={row} displayStyle={displayStyle} idx={idx} register={register} control={control}/>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const ProcedureCodesSection = ({
                                          officeForm,
                                          errors,
                                          register,
                                          control
                                      })=>{
    const [query, setQuery] = useState('');
    const [data, setData] = useState(officeForm?.procedure_codes ?? [])
    const [shouldShowRow, setShouldShowRow] = useState([])
    /*--------------------------------------------
    Fuzzy search
    --------------------------------------------*/
    const fuzzySearch=(q)=>{
        const options = {
            includeScore: true,
            keys: [
                {
                    name: 'procedure_code',
                    weight: 1
                },
                {
                    name: 'category',
                    weight: 1
                },
                {
                    name:'synonyms',
                    weight:1
                }
            ],
            threshold:0.1,
        }
        const fuse = new Fuse(data, options)
        return fuse.search(q)?.map(a=>a.item?.procedure_code)
    }


    return <>
        <SearchBar
            placeholder={'Search by procedure code, category, etc...'}
            style={{margin: '10px 0'}}
            value={query}
            onChange={(newValue) => {
                const debounced = _.debounce(()=>{
                    setShouldShowRow(fuzzySearch(newValue))
                }, 200)
                debounced();
            }
            }
        />
        {/*<pre>*/}
        {/*    {JSON.stringify(shouldShowRow, null, 2)}*/}
        {/*</pre>*/}
        <DenseTable data={data} control={control} register={register} shouldShowRow={shouldShowRow}/>
    </>
}