import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "@apollo/client";
import {GET_PAST_OFFICE_FORMS} from "./pastSearchQueries";
import Pagination from '@material-ui/lab/Pagination';
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Box, Button, Card, Grid, LinearProgress, Paper} from "@material-ui/core";

const SearchResults = ({page_count, feed, navToDifferentPage, page = 1, navToOfficeForm})=>{
    return <div>
        PAST SEARCH:
        {Array.isArray(feed) && feed.map(a=>{
            return(
                <div>
                    <Grid container style={{margin: '20px'}}>
                        <div style={{margin: '20px'}}></div>
                        <Grid item>
                            <p>{a.onederfulId}</p>
                            <p>{a?.first_name} {a?.last_name}</p>
                            <p>{a?.dob} {a?.member_id}</p>
                            <p>{a?.payer_name}</p>
                            <p>{a?.status}</p>
                        </Grid>
                        <Button onClick={()=>navToOfficeForm(a?.onederfulId, a?.is_custom)} color={'primary'}>
                            Open Office Form
                        </Button>
                    </Grid>
                </div>
            )
        })}
        <Pagination
            count={page_count}
            color="primary"
            onChange={navToDifferentPage}
            page={page}
        />
    </div>
}

const NUM_ITEMS_ON_PAGE = 10
export const PastSearch = (props)=>{
    const {isAuthenticated} = useAuth0();
    const history = useHistory();
    const { id } = useParams();
    const intId = parseInt(id)
    let offset = 0
    if(intId){
        offset = (intId-1)*NUM_ITEMS_ON_PAGE
    }

    const { loading, data, fetchMore } = useQuery(GET_PAST_OFFICE_FORMS, {
        variables: {
            offset: parseInt(offset) || 0,
            limit:NUM_ITEMS_ON_PAGE,
        },
    });
    /*--------------------------------------------
    Page ID
    --------------------------------------------*/
    const navToDifferentPage =(e, pageId)=>{
        history.push(`/past-search/${pageId}`)
    }
    const navToOfficeForm = (onederfulId)=>{
        history.push(`/office-form/${onederfulId}`)
    }
    if(loading){
        return <div style={{margin:'50px'}}>
            <LinearProgress />
        </div>
    }

    return (
        isAuthenticated && (
            <SearchResults
                feed={data?.getPastOfficeForms?.feed}
                navToDifferentPage={navToDifferentPage}
                page_count={Math.ceil(parseInt(data?.getPastOfficeForms?.num_of_records) / NUM_ITEMS_ON_PAGE)}
                page={parseInt(id)||1}
                navToOfficeForm={navToOfficeForm}
            />
        )
    )
}