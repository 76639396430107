import {cdtCodes} from "./cdtCodes";
import {adaCategories} from "./adaCategories";
import _ from 'lodash'

const DEFAULT_EMPTY_STRING = 'SELECT...'

export const YesNoOptions = [{value:false, label:'No'}, {value:true, label:'Yes'}, {value: null, label:DEFAULT_EMPTY_STRING}]
export const RelationshipToSubscriberOptions = [{value:'SELF', label:'SELF'}, {value:'DEPENDENT', label:'DEPENDENT'}]
export const SeatOrPrepDateOptions = [{value:'seat', label:'Seat'}, {value:'prep', label:'Prep'}, {value: null, label:DEFAULT_EMPTY_STRING}]
export const CDTOptions = cdtCodes.map(a=>({label:a, value:a}))
export const PeriodOptions = [{value: 'calendar', label:'Calendar'}, {value: 'contract', label:'Contract'}, {value:null, label: DEFAULT_EMPTY_STRING}]
export const CoverageStatusOptions = [{value: 'active', label:'Active'}, {value: 'terminated', label:'Terminated'}, {value: 'inactive', label:'Inactive'}, {value:null, label:DEFAULT_EMPTY_STRING}]
export const ADACategoryOptions = _.map(adaCategories, (v, k)=>({value: k, label: v}))
export const TimePeriodOptions = ['Months', 'Weeks', 'Lifetime', 'Years', 'Days'].map(a=>({value: a, label:a}))
export const COBRuleOptions = [{value:null , label: DEFAULT_EMPTY_STRING},
    {value: 'traditional', label: 'Traditional'},
    {value: 'non_duplication', label: 'Non-Duplication'},
    {value: 'maintenance_of_benefits', label: 'Maintenance of Benefits'}
]

/*--------------------------------------------
TODO: move these to backend
--------------------------------------------*/
export const GUARDIAN_NETWORKS = {
    DG_GOLD: 'DG GOLD',
    DG_SILVER:'DG SILVER',
    DG_PLATINUM:'DG PLATINUM',
    oon: 'out_of_network',
    in_network:'in_network'
}

export const CIGNA_NETWORKS = {
    CIGNA_DPPO_ADVANTAGE:'CIGNA DPPO ADVANTAGE',
    CIGNA_DPPO:'CIGNA DPPO',
    out_of_network:'out_of_network'
}

export const Simple2NetworksOptions = [
    {    value: 'in_network', label : 'In Network'},
    {value: 'out_of_network', label: 'Out of Network'}
]


export const DD_NETWORKS = {
    PPO:'PPO',
    PREMIER:'PREMIER',
    OON:'OON'
}

export const DDNetworkOptions = [
    {value: DD_NETWORKS.PPO, label:'PPO',},
    {value: DD_NETWORKS.PREMIER, label: 'PREMIER'},
    {value: DD_NETWORKS.OON, label: 'Out Of Network'}
]

export const CignaNetworkOptions = [
    {value:CIGNA_NETWORKS.CIGNA_DPPO_ADVANTAGE, label:'In Network - CIGNA DPPO ADVANTAGE'},
    {value: CIGNA_NETWORKS.CIGNA_DPPO, label: 'In Network - CIGNA DPPO'},
    {value: CIGNA_NETWORKS.out_of_network, label: 'Out Of Network'}
]

export const GuardianNetworkOptions = [
    {value: GUARDIAN_NETWORKS.in_network, label: 'In Network'},
    {value: GUARDIAN_NETWORKS.oon, label: 'Out Of Network'},
    {value: GUARDIAN_NETWORKS.DG_GOLD, label: 'DG GOLD'},
    {value: GUARDIAN_NETWORKS.DG_SILVER, label: 'DG SILVER'},
    {value: GUARDIAN_NETWORKS.DG_PLATINUM, label: 'DG PLATINUM'},
]



export const PayerOptions = [
    {value: 'DD_CALIFORNIA', label:'Delta Dental of California',},
    {value: 'AETNA_DENTAL_PLANS', label:'Aetna'},
    {value: 'GUARDIAN', label:'Guardian'},
    {value:'UNITED_CONCORDIA', label: 'United Concordia'},
    {value: 'METLIFE', label:'Metlife'},
    {value: 'CIGNA', label:'Cigna',},
    {value:'UNITED_HEALTHCARE', label: 'United Healthcare'},
    {value: 'DD_PENNSYLVANIA', label:'Delta Dental of Pennsylvania',},
    {value: 'DD_GEORGIA', label:'Delta Dental of Georgia',},
    {value: 'DD_NEW_YORK', label:'Delta Dental of New York',},
    {value: 'DD_NEW_JERSEY', label:'Delta Dental of New Jersey',},
]