import { gql, useMutation } from '@apollo/client';

export const SAVE_OFFICE_FORM = gql`
    mutation saveOfficeForm($office_form:InputOfficeFormSave, $onederfulId:String!){
        saveOfficeForm(office_form:$office_form, onederfulId:$onederfulId)
    }
`;

export const SAVE_CUSTOM_FORM = gql`
    mutation saveCustomForm($office_form:InputOfficeFormSave, $onederfulId:String!){
        saveCustomForm(office_form:$office_form, onederfulId:$onederfulId)
    }
`;