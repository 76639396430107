import React from "react";
import {Grid, TextField} from "@material-ui/core";
import {RenderNonEditableField, RenderNonEditableLink, RenderTextField} from "../../../components/form-fields";
import {ContentCard} from "../../../components/cards/ContentCard";
import {SECTION_VERSION} from "./constants";

export const PayerSection = ({
                                 officeForm,
                                 errors,
                                 register,
                                 control,
                                 version
                             })=>{
    const editableVersion = ()=>{
        return <>
            <RenderTextField
                control={control}
                name="payer.id"
                label={'e-claim ID'}
                defaultValue={officeForm?.payer?.id}
                inputRef={register} />
            <RenderTextField
                control={control}
                name="payer.phone_number"
                label={'Phone Number'}
                defaultValue={officeForm?.payer?.phone_number}
                inputRef={register} />
            <RenderTextField
                control={control}
                name="payer.name"
                label={'Name'}
                defaultValue={officeForm?.payer?.name}
                inputRef={register} />
            <RenderTextField
                control={control}
                name="payer.website"
                label={'Website'}
                defaultValue={officeForm?.payer?.website}
                inputRef={register} />
        </>
    }
    const defaultVersion = ()=>{
        return <>
            {
                officeForm?.is_custom ?
                    <RenderTextField
                        control={control}
                        name="payer.id"
                        label={'e-claim ID'}
                        defaultValue={officeForm?.payer?.id}
                        inputRef={register} />:
                <RenderNonEditableField
                    control={control}
                    name="payer.id"
                    label={'e-claim ID'}
                    value={officeForm?.payer?.id}
                    inputRef={register} />
            }
            <RenderNonEditableField
                control={control}
                name="payer.phone_number"
                label={'Phone Number'}
                value={officeForm?.payer?.phone_number}
                inputRef={register} />
            <RenderNonEditableField
                control={control}
                name="payer.name"
                label={'Name'}
                value={officeForm?.payer?.name}
                inputRef={register} />
            <RenderNonEditableLink
                control={control}
                name="payer.website"
                label={'Website'}
                value={officeForm?.payer?.website}
                inputRef={register} />
        </>
    }
    return <ContentCard title={'Payer'}>
        <Grid
            container
            direction="column"
        >
            {editableVersion()}
        </Grid>
    </ContentCard>
}