import {Grid, Paper} from "@material-ui/core";
import './ContentCard.css'

export const ContentCard = ({children, title, tip})=>{
    return <Paper elevation={3} className={'content-card'}>
        {tip && <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
            <h4><b>{title}</b></h4>
            {tip}
        </Grid>}
        {!tip && <h4><b>{title}</b></h4>}
        {children}
    </Paper>
}