import React from "react";
import {Grid, TextField} from "@material-ui/core";
import {RenderSingleSelect, RenderTextField} from "../../../components/form-fields";
import {YesNoOptions} from "../../../constants/selectOptions";
import {ContentCard} from "../../../components/cards/ContentCard";

export const MaximumSection = ({
                                   officeForm,
                                   errors,
                                   register,
                                   control
                               })=>{
    return <ContentCard title={'Maximum'}>
        <Grid
            container
            direction="column"
        >
            <RenderTextField
                name="maximum"
                label={'Maximum'}
                defaultValue={officeForm?.maximum}
                inputRef={register} />
            <RenderTextField
                name="maximum_remaining"
                label={'Maximum remaining'}
                defaultValue={officeForm?.maximum_remaining}
                inputRef={register} />
            {/*<RenderSingleSelect*/}
            {/*    control={control}*/}
            {/*    name={'does_maximum_apply_to_diagnostic'}*/}
            {/*    defaultValue={officeForm?.does_maximum_apply_to_diagnostic}*/}
            {/*    options={YesNoOptions}*/}
            {/*    label={'Does maximum apply to diagnostic?'}*/}
            {/*/>*/}
            <RenderSingleSelect
                control={control}
                name={'does_maximum_apply_to_preventive'}
                defaultValue={officeForm?.does_maximum_apply_to_preventive}
                options={YesNoOptions}
                label={'Does maximum apply to preventive?'}
            />
        </Grid>
    </ContentCard>
}