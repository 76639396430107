export const API_ENDPOINT = () => {
    if (process.env.REACT_APP_VYNE_ENV === 'staging') {
        return 'https://staging.onederful.co'
    }
    return process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://production.onederful.co'
}

export const ENDPOINTS = {
    PAYERS: `${API_ENDPOINT()}/payer/patientyes`
}
