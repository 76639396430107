import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useAuth0} from "@auth0/auth0-react";
import {LogoutButton} from "../features/logout/LogoutButton";
import {LoginButton} from "../features/login/LoginButton";
import {useHistory, Link} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {NAV_BAR_ROUTES} from "../config/routes";
import axios from 'axios'
import {API_ENDPOINT} from "../config/endpoints";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));
export const NavBar = ()=>{
    const {isAuthenticated,getAccessTokenSilently} = useAuth0();
    const history = useHistory();
    const classes = useStyles();

    const createBlankForm = async()=>{
        const token = await getAccessTokenSilently()
        const onederfulId = await axios.post(`${API_ENDPOINT()}/office-form/custom-form`, {}, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        }).then(({data})=>data?.onederfulId)
        history.push(`/office-form/${onederfulId}`)
    }

    return <AppBar position="static">
        <Toolbar>
            <Typography variant="h6" className={classes.title}>
                Patient Yes
            </Typography>
            {NAV_BAR_ROUTES.map(
                ({title, route})=>
                    isAuthenticated && <Button color={'inherit'} onClick={()=>history.push(route)}>{title}</Button>
            )}
            {isAuthenticated && <Button color={'inherit'} onClick = {()=>createBlankForm()}>Blank Form</Button>}
            {isAuthenticated && <LogoutButton/>}
            {!isAuthenticated && <LoginButton/>}
        </Toolbar>
    </AppBar>
}