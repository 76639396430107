import {capitalize, Grid, TextField} from "@material-ui/core";
import React from "react";
import {
    RenderDatePicker,
    RenderNonEditableField,
    RenderSingleSelect,
    RenderTextField
} from "../../../components/form-fields";
import {ContentCard} from "../../../components/cards/ContentCard";
import {SECTION_VERSION} from "./constants";
import {RelationshipToSubscriberOptions} from "../../../constants/selectOptions";

export const PatientSection = ({
                                   officeForm,
                                   errors,
                                   register,
                                   control,
                                   version
                               })=>{
    return <ContentCard title={'Patient'}>
        <Grid
            container
            direction="column"
        >
            {version === SECTION_VERSION.EDITABLE ? <RenderSingleSelect
                control={control}
                defaultValue={officeForm?.patient?.relationship}
                name={'patient.relationship'}
                options={RelationshipToSubscriberOptions}
                label={'Relationship to Subscriber'}
            /> : <RenderNonEditableField
                label={'Relationship to Subscriber'}
                name="patient.relationship"
                value={capitalize(officeForm?.patient?.relationship || '')}
                control={control} />}
            <RenderTextField
                name="patient.first_name"
                label={'First Name'}
                defaultValue={officeForm?.patient?.first_name}
                error={Boolean(errors?.patient?.first_name)}
                helperText = {errors.patient?.first_name?.message}
                inputRef={register}
                autofocus={true}
            />
            <RenderTextField
                label={'Last Name'}
                name="patient.last_name"
                defaultValue={officeForm?.patient?.last_name}
                error={Boolean(errors?.patient?.last_name)}
                helperText = {errors.patient?.last_name?.message}
                inputRef={register} />
            <RenderDatePicker  label={'Date of Birth'} control={control} name={'patient.dob'} defaultValue={officeForm?.patient?.dob}/>
            <RenderTextField
                label={'Member Id'}
                name="patient.member_id"
                defaultValue={officeForm?.patient?.member_id}
                error={Boolean(errors?.patient?.member_id)}
                helperText = {errors.patient?.member_id?.message}
                inputRef={register} />
            {version === SECTION_VERSION.EDITABLE ? null : <div className={'non-editable-field'} style={{margin:'5px 0'}}>
                <label>Age</label>
                <div>{officeForm?.patient?.age}</div>
            </div>}
            {/*<TextField*/}
            {/*    label={'Relationship'}*/}
            {/*    name="patient.relationship"*/}
            {/*    defaultValue={officeForm?.patient?.relationship}*/}
            {/*    error={Boolean(errors?.patient?.relationship)}*/}
            {/*    helperText = {errors.patient?.relationship?.message}*/}
            {/*    inputRef={register} />*/}
        </Grid>
    </ContentCard>
}