import {gql} from "@apollo/client";

export const GET_PAST_OFFICE_FORMS = gql`
    query getPastOfficeForms($offset: Int, $limit: Int) {
        getPastOfficeForms(offset: $offset, limit: $limit) {
            num_of_records
            feed {
                timestamp
                onederfulId
                first_name
                last_name
                dob
                member_id
                payer_name
                group_number
                network
                payer_specific_description
                coverage_status
                is_custom
            }
        }
    }



`;