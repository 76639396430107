import React, { useContext, useState, useEffect, createContext } from "react";
import axios from 'axios'
import {ENDPOINTS} from "../../config/endpoints";

const PayersAPIContext = createContext({});

async function getPayers(){
    return await axios.get(ENDPOINTS.PAYERS)
}

function PayersAPIContextProvider({ children }) {
    // Initialize state
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    // Fetch data
    useEffect(() => {
        getPayers()
            .then(({data})=> {
                setData(data)
                setIsLoading(false)
            })
            .catch(e=>console.log(e))
    }, []);

    return (
        <PayersAPIContext.Provider value={{ data, isLoading }}>
            {children}
        </PayersAPIContext.Provider>
    );
}

function usePayerAPI() {
    const context = useContext(PayersAPIContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
}

export {PayersAPIContextProvider, usePayerAPI}