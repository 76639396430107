import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloLink } from "apollo-link";
import { offsetLimitPagination } from "@apollo/client/utilities";
import omitDeep from 'omit-deep'

import { onError } from "@apollo/client/link/error";
import {API_ENDPOINT} from "../config/endpoints";

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});

export const AuthorizedApolloProvider = ({children})=> {
    const { getAccessTokenSilently } = useAuth0();
    const httpLink = createHttpLink({
        uri: `${API_ENDPOINT()}/graphql`,
    });
    const authLink = setContext(async () => {
        const token = await getAccessTokenSilently({
            audience:'https://production.onederful.co'
        });
        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
    });
    const apolloClient = new ApolloClient({
        link: from([cleanTypeName, errorLink, authLink, httpLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    feed: {
                        ...offsetLimitPagination(),
                        read(existing, { args }) {
                            // Implement here
                        }
                    }
                },
            },
        }),
        connectToDevTools: true
    });
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
