import _ from 'lodash'
import React from "react";
import {Box, Grid} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {CDTCategories} from "../../components/chips/CDTCategoryChip";

import './PrintEBView.css'
import {
    ADACategoryOptions,
    COBRuleOptions,
    CoverageStatusOptions,
    PeriodOptions,
    SeatOrPrepDateOptions,
    YesNoOptions
} from "../../constants/selectOptions";

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        width:1000,
    },
});

function createFrequencyString(frequency_component){
    if(frequency_component?.quantity){
        return `${frequency_component?.quantity} x ${frequency_component?.time_period_value} ${frequency_component?.time_period_qualifier}`
    }
    return null
}

/*--------------------------------------------
PROCEDURE CODES
--------------------------------------------*/
function DenseTable({ mergedForm}) {
    const classes = useStyles();
    return (
        <TableContainer component={Paper} className={'procedure-code-tbl'}>
            <Table  size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow className={'tbl-row'}>
                        <TableCell style={{width:'5%'}}><p className={'table-header-text'}>CDT</p></TableCell>
                        <TableCell style={{width:'5%'}}>
                            <Box flexDirection={'column'}>
                                <p className={'table-header-text'}>Group</p>
                                <p className={'table-header-text'}>Common</p>
                            </Box>
                        </TableCell>
                        <TableCell style={{width:'5%'}}><p className={'table-header-text'}>%</p></TableCell>
                        <TableCell><p className={'table-header-text'}>Freq</p></TableCell>
                        <TableCell><p className={'table-header-text'}>Age</p></TableCell>
                        <TableCell><p className={'table-header-text'}>History</p></TableCell>
                        <TableCell><p className={'table-header-text'}>Shares Frequency With</p></TableCell>
                        <TableCell><p className={'table-header-text'}>Notes</p></TableCell>
                    </TableRow>
                </TableBody>
                <TableBody>
                    {Array.isArray(mergedForm) && mergedForm.map((d, idx) => (
                        <TableRow className={'tbl-row'}>
                            <TableCell component="th" scope="row">
                                <p className={'table-text'}>{d?.procedure_code}</p>
                            </TableCell>
                            <TableCell align="left">
                                <Box flexDirection={'column'}>
                                    <p className={'table-text'}>{CDTCategories[mergedForm?.[idx]?.category]?.displayName}</p>
                                    <p className={'table-text'}>{_.first(mergedForm?.[idx]?.synonyms)}</p>
                                </Box>
                                {/*{[CDTCategories[data?.[idx]?.category]?.displayName, _.first(data?.[idx]?.synonyms)].filter(a=>a).join(' - ')}*/}
                            </TableCell>
                            {d?.is_not_covered ? <TableCell component="th" scope="row">
                                <p className={'table-text'}>NOT COVERED</p>
                            </TableCell> : <TableCell component="th" scope="row">
                                <p className={'table-text'}>{d?.percent}</p>
                            </TableCell>}


                            <TableCell component="th" scope="row">
                                {Array.isArray(d?.limitations) && d?.limitations.map(limitation=>{
                                    return <p className={'table-text'}>{createFrequencyString(limitation?.frequency_component)}</p>
                                })}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {Array.isArray(d?.limitations) && d?.limitations.map(limitation=>{
                                    if(limitation?.age_high_value){
                                        return <p className={'table-text'}>High: {limitation?.age_high_value}</p>
                                    }
                                    if(limitation?.age_low_value){
                                        return <p className={'table-text'}>Low: {limitation?.age_low_value}</p>
                                    }
                                    return null
                                })}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <p className={'table-text'}>{Array.isArray(d?.history) && d?.history.map(({service_date})=>{
                                    return service_date
                                }).join(', ')}</p>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <p className={'table-text'}>{Array.isArray(d?.limitation_applies_to) && d?.limitation_applies_to?.join(', ')}</p>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <p className={'table-text'}>{d?.notes}</p>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function findSelectOptionValue(value, options){
    return _.find(options, option=>option.value===value)?.label
}

export class PrintEBView extends React.Component {
    render() {
        const {data, formData} = this.props
        const mergedForm = _.merge(JSON.parse(JSON.stringify(data)), formData)
        return (<div className={'print-office-form'}>
            <h3>Eligibility & Benefits Verification</h3>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <p><b>Patient Information</b></p>
                    <p>Patient First Name: {mergedForm?.patient?.first_name}</p>
                    <p>Patient Last Name: {mergedForm?.patient?.last_name}</p>
                    <p>Patient DOB: {mergedForm?.patient?.dob}</p>
                    <p>Patient Member ID: {mergedForm?.patient?.member_id}</p>
                    <p>Patient Age: {mergedForm?.patient?.age}</p>
                    <p>Patient Relationship to Subscriber: {mergedForm?.patient?.relationship}</p>
                </Grid>
                <Grid item xs={4}>
                    <p><b>Payer Information</b></p>
                    <p>Insurance Carrier: {mergedForm?.payer?.name}</p>
                    <p>E-Claim ID: {mergedForm?.payer?.id}</p>
                    <p>Phone Number: {mergedForm?.payer?.phone_number}</p>
                    <p>Provider Portal: {mergedForm?.payer?.website}</p>
                </Grid>
                <Grid item xs={4}>
                    <p><b>Deductible</b></p>
                    <p>Individual Deductible: {mergedForm?.individual_deductible}</p>
                    <p>Individual Deductible Remaining: {mergedForm?.individual_deductible_remaining}</p>
                    <p>Family Deductible: {mergedForm?.family_deductible}</p>
                    <p>Family Deductible Remaining: {mergedForm?.family_deductible_remaining}</p>
                    <p>Should Apply Deductible to Diagnostic?: {findSelectOptionValue(mergedForm?.should_apply_deductible_to_diagnostic, YesNoOptions)}</p>
                    <p>Should Apply Deductible to Preventive?: {findSelectOptionValue(mergedForm?.should_apply_deductible_to_preventive, YesNoOptions)}</p>
                </Grid>
                <Grid item xs={4}>
                    <p><b>Plan Information</b></p>
                    <p>Status: {findSelectOptionValue(mergedForm?.coverage?.status, CoverageStatusOptions)}</p>
                    <p>Effective Date: {mergedForm?.coverage?.effective_date}</p>
                    <p>Start Date: {mergedForm?.coverage?.start_date}</p>
                    <p>End Date: {mergedForm?.coverage?.end_date}</p>
                    <p>Group Name: {mergedForm?.plan?.name}</p>
                    <p>Group Number: {mergedForm?.plan?.group_number}</p>
                    <p>Plan Period: {findSelectOptionValue(mergedForm?.plan?.period, PeriodOptions)}</p>
                    <p>Missing Tooth Clause: {findSelectOptionValue(mergedForm?.plan?.missing_tooth_clause, YesNoOptions)}</p>
                    <p>COB: {findSelectOptionValue(mergedForm?.plan?.cob_rule, COBRuleOptions)}</p>
                    <p>Downgrades for crowns?: {findSelectOptionValue(mergedForm?.plan?.downgrade_on_crowns, YesNoOptions)}</p>
                    <p>Downgrades for fillings?: {findSelectOptionValue(mergedForm?.plan?.downgrade_on_fillings, YesNoOptions)}</p>
                    <p>Paid on Seat or Prep Date?: {findSelectOptionValue(mergedForm?.plan?.seat_or_prep_date, SeatOrPrepDateOptions)}</p>
                </Grid>
                <Grid item xs={4}>
                    <p><b>Waiting Period</b></p>
                    {
                        Array.isArray(mergedForm?.effective_dates) && mergedForm?.effective_dates.map(waiting_period=>{
                            return <p>{findSelectOptionValue(waiting_period?.category, ADACategoryOptions)} - {waiting_period?.effective_date}</p>
                        })
                    }
                </Grid>
                <Grid item xs={4}>
                    <p><b>Maximum</b></p>
                    <p>Individual Deductible: {mergedForm?.maximum}</p>
                    <p>Individual Deductible Remaining: {mergedForm?.maximum_remaining}</p>
                    <p>Should Apply Preventive to Maximum?: {findSelectOptionValue(mergedForm?.does_maximum_apply_to_preventive, YesNoOptions)}</p>
                    <p><b>Orthodontics Information</b></p>
                    <p>Orthodontics Maximum: {mergedForm?.ortho_maximum}</p>
                    <p>Orthodontics Maximum Remaining: {mergedForm?.ortho_maximum_remaining}</p>
                </Grid>
                <DenseTable mergedForm={mergedForm?.procedure_codes}/>
            </Grid>
        </div>)
    }
}