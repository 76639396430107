import {AutocompleteComboBox} from "../../components/autocomplete/AutocompleteComboBox";
import {usePayerAPI} from "./PayersAPIContext";

export function PayerSearchAutocomplete({onChange}){
    // @ts-ignore
    const {data, isLoading} = usePayerAPI()
    if(isLoading){
        return <div>LOADING</div>
    }
    return <AutocompleteComboBox
        items={
            data?.map(d=>({
                primary:d?.payer_name?.toUpperCase(),
                secondary: d?.patientyes_indicator === 'MAPPED' ? 'PREMIUM': '',
                onederfulId: d?.onederfulId,
                patientyes_indicator: d?.patientyes_indicator,
            }))}
        label={'Payer'}
        placeholder={'(selection required)'}
        onSelectedItemChange={({selectedItem})=>{
            onChange({
                id: selectedItem?.onederfulId,
                patientyes_indicator:selectedItem?.patientyes_indicator
            })
        }}
        onBlur={()=>onChange(null)}
    />
}