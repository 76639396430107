import {useAuth0} from "@auth0/auth0-react";
import _ from 'lodash'
import axios from 'axios'

export const AxiosWrapper = {
    post:async({url, data})=>{
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently({
            audience:'https://production.onederful.co'
        });
        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return await instance.post(url, data)
    }
}

export function pruneEmpty(obj) {
    return function prune(current) {
        _.forOwn(current, function (value, key) {
            if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isObject(value) && _.isEmpty(prune(value)))) {

                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (_.isArray(current)) _.pull(current, undefined);

        return current;

    }(_.cloneDeep(obj));  // Do not modify the original object, create a clone instead
}
