import React from "react";
import {Grid, TextField} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import {
    RenderDatePicker,
    RenderInvisibleField,
    RenderMultiSelect,
    RenderSingleSelect
} from "../../../components/form-fields";
import {ADACategoryOptions, CoverageStatusOptions, PeriodOptions, YesNoOptions} from "../../../constants/selectOptions";
import {ContentCard} from "../../../components/cards/ContentCard";
import {MouseOverPopover} from "../../../components/popover";
import {useFieldArray} from "react-hook-form";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {red} from "@material-ui/core/colors";
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from "@material-ui/core/IconButton";
const redTheme = createMuiTheme({ palette: { primary: red } })

const useStyles = makeStyles(theme=>({
        button: {
            backgroundColor: theme.palette.error.main,
        }
    }
));
export const WaitingPeriodSection = ({
                                         officeForm,
                                         errors,
                                         register,
                                         control
                                     })=>{
    const classes = useStyles();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "effective_dates", // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    })
    return <div
    >
        <ContentCard title={'Waiting Period'}
                     tip={officeForm?.expert_tips_waiting_period && <MouseOverPopover message={officeForm?.expert_tips_waiting_period}/>}>
            <Grid container direction={'row'} xs={12} md={12}>
                { Array.isArray(fields) && fields.map((item, index) => {
                    // if(!item?.is_a_waiting_period){
                    //     return null
                    // }
                    return (
                        <Grid container key={item.id} direction={'row'} xs={12} md={6} spacing={3}
                              alignItems={'center'}
                        >
                            <Grid item xs={12} md={6}>
                                <RenderSingleSelect
                                    control={control}
                                    name ={`effective_dates[${index}].category`}
                                    label={'Category'}
                                    defaultValue={item.category}
                                    options = {ADACategoryOptions}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} style={{paddingTop:'30px'}}>
                                <RenderDatePicker
                                    control={control}
                                    name ={`effective_dates[${index}].effective_date`}
                                    defaultValue={item.effective_date}
                                    label={'Effective Date'}
                                />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <RenderInvisibleField
                                    control={control}
                                    name={`effective_dates[${index}].is_a_waiting_period`}
                                    value={item.is_a_waiting_period ? item.is_a_waiting_period: true}
                                />
                                <MuiThemeProvider theme={redTheme}>
                                    <IconButton
                                        color={'primary'}
                                        aria-label="delete"
                                        onClick={() => remove(index)}>
                                        <CancelIcon />
                                    </IconButton>
                                </MuiThemeProvider>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        append({});
                    }}
                >
                    Add
                </Button>
            </Grid>
        </ContentCard>
    </div>
}