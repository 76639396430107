import _ from 'lodash'
import React, {useEffect, useRef, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router";
import {useMutation, useQuery} from '@apollo/client';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useReactToPrint} from "react-to-print";
import {GET_OFFICE_FORM} from "./officeFormQueries";
import {PatientSection} from "./sections/PatientSection";
import {PayerSection} from "./sections/PayerSection";
import {PlanSection} from "./sections/PlanSection";
import {MaximumSection} from "./sections/MaximumSection";
import {DeductibleSection} from "./sections/DeductibleSection";
import {WaitingPeriodSection} from "./sections/WaitingPeriodSection";
import {ProcedureCodesSection} from "./sections/ProcedureCodesSection";
import {OrthoSection} from "./sections/OrthoSection";
import PinterestGrid from "../../components/pinterest-grid";
import {CircularProgress, Fab, Tab, Tabs} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Loading} from "../../components/loading";
import {PageMargins} from "../../layout/PageMargins";
import './OfficeForm.css'
import ReactJson from 'react-json-view'
import {PrintEBView} from "./PrintEBView";
import Button from "@material-ui/core/Button";
import {ProviderSection} from "./sections/ProviderSection";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardArrowLeft} from "@material-ui/icons";
import {SAVE_OFFICE_FORM} from "./officeFormMutations";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useEBData} from "../../features/EB/EBDataContext";

const schema = yup.object().shape({
    patient:yup.object().shape({
        first_name:yup.string(),
        last_name:yup.string(),
        relationship:yup.string(),
    })
});


export const OfficeForm = () => {
    // @ts-ignore
    const {ebResponse, setEBResponse} = useEBData()
    const  { onederfulId } = useParams();
    const [currentFormValues, setCurrentFormValues] = useState({})
    const { loading, error, data } = useQuery(GET_OFFICE_FORM,
        {
            variables:{
                onederfulId
            },
        });
    const officeForm = data?.getOfficeForm
    const history = useHistory();
    const componentRef = useRef();
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState('office_form');

    const { handleSubmit, register, errors, control, reset, getValues  } = useForm({
        resolver: yupResolver(schema),
        mode:'all'
    });

    useEffect(()=>{
        if(officeForm){
            reset({
                ...officeForm,
                effective_dates:officeForm?.effective_dates || [],
            })
        }
    }, [reset, officeForm])

    const handleNotificationOpen = ()=>{
        setOpen(true);
    }

    const handleNotificationClose = (event, reason) => {
        setOpen(false);
    };

    const {isAuthenticated, isLoading } = useAuth0();

    const [saveOfficeForm, {
        data:mutationData,
        loading: mutationLoading,
        error: mutationError,
    }] = useMutation(SAVE_OFFICE_FORM);



    const onSubmit = async (updatedData, e)=>{
        handleNotificationClose();
        const {html, ...mergedForm} = _.merge(JSON.parse(JSON.stringify(officeForm)), updatedData)
        await saveOfficeForm({
            variables:{
                onederfulId,
                office_form: mergedForm
            }
        }).catch(e=>console.log(e))
        handleNotificationOpen()
    }

    const changeTab =(values)=>{
        setCurrentFormValues(values)
    }


    const changeToSearch =()=>{
        history.push('/eb-request-form')
    }

    const handlePrint = useReactToPrint({
        content:()=>componentRef.current
    })

    /*--------------------------------------------
    Workaround to update value
    --------------------------------------------*/
    const doPrint = (values)=>{
        setCurrentFormValues(values)
        handlePrint()
    }

    if(error){
        return error?.graphQLErrors?.[0]?.errors?.[0]?.message
    }
    if (isLoading || loading) {
        return <PageMargins>
            <Loading/>
        </PageMargins>
    }

    const renderHTML = ()=>{
        if(tab === 'HTML' && officeForm?.html){
            return <div dangerouslySetInnerHTML={{__html:officeForm?.html}} />
        }
        return null
    }

    return (
        isAuthenticated && (
            <div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={handleNotificationClose} severity="success">
                        {'Office Form Saved!'}
                    </MuiAlert>
                </Snackbar>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, newValue)=>setTab(newValue)}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Office Form" value={'office_form'}/>
                    <Tab label="JSON" value={'json'} onClick={()=>changeTab(getValues())}/>
                    <Tab label="HTML" value={'HTML'}/>
                    {/*<Tab label="Print View" value={'print_view'} onClick={()=>changeTab()}/>*/}
                </Tabs>
                <div style={{display: tab!=='json' ? 'none': 'inherit'}}>
                <pre>
                    <ReactJson src={currentFormValues} />
                </pre>
                </div>
                {renderHTML()}

                <Button style={{margin: '10px 30px'}}
                        variant={'outlined'}
                        color={'primary'}
                        onClick={()=>{
                            return doPrint(getValues())
                        }}
                        disabled={mutationLoading}
                >Print</Button>
                <PrintEBView ref={componentRef} formData={currentFormValues} data={officeForm}/>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <PinterestGrid>
                            <PatientSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                            <ProviderSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                            <PlanSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                            <PayerSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                            <OrthoSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                            <DeductibleSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                            <MaximumSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                        </PinterestGrid>
                        <WaitingPeriodSection errors={errors} officeForm={officeForm} register={register} control={control}/>
                        <ProcedureCodesSection errors={errors} officeForm={officeForm} register={register} control={control}/>

                        <Fab
                            color='primary'
                            variant="extended"
                            style={{
                                margin:'0',
                                top: 'auto',
                                right: '20px',
                                bottom: '50px',
                                left: 'auto',
                                position: 'fixed',
                                zIndex:'999'
                            }}
                            type={'submit'}
                            disabled={mutationLoading}
                        >
                            {mutationLoading ? <CircularProgress/>: 'Save'}
                        </Fab>
                        {/*<button onClick={()=>reset({patient:{first_name:'test'}})}>Reset</button>*/}
                        {/*<input type="submit" value="Add" />*/}
                    </form>
                </div>
            </div>
        )
    );
};
