import React, { Component } from 'react';
import {withSize} from 'react-sizeme';
import StackGrid from 'react-stack-grid';

function MyComponent({ size, children }) {
    return <>
        <div style={{height:0, opacity:0}}>a{size.width}</div>
        <StackGrid columnWidth={size.width <= 768 ? '100%' : '33.33%'}>
            {children}
        </StackGrid>
    </>
}

export default withSize()(MyComponent);