import { v4 as uuidv4 } from 'uuid';
export enum ROUTE_ENUM {
    PROTECTED_LANDING_VIEW = '/',
    PAST_SEARCH_VIEW='/past-search/:id',
    EB_HTML_REQUEST_VIEW = '/eb-html-request',
    OFFICE_FORM_VIEW= '/office-form/:onederfulId'
}
type NavBarRouteType = {
    title:string
    route: ROUTE_ENUM
}

export const NAV_BAR_ROUTES:NavBarRouteType[] = [
    {title: 'E&B Search', route: ROUTE_ENUM.EB_HTML_REQUEST_VIEW},
    {title:'Past Search', route:ROUTE_ENUM.PROTECTED_LANDING_VIEW},
]