import React from "react";
import {Grid, TextField} from "@material-ui/core";
import {RenderNonEditableField, RenderNonEditableLink, RenderTextField} from "../../../components/form-fields";
import {ContentCard} from "../../../components/cards/ContentCard";
import {SECTION_VERSION} from "./constants";

export const ProviderSection = ({
                                    officeForm,
                                    errors,
                                    register,
                                    control,
                                    version
                                })=>{
    const editableVersion = ()=>{
        return <>
            <RenderTextField
                control={control}
                name="network"
                label={'Network'}
                defaultValue={officeForm?.network}
                inputRef={register} />
            <RenderTextField
                control={control}
                name="payer_specific_description"
                label={'Network Specific'}
                defaultValue={officeForm?.payer_specific_description}
                inputRef={register} />
            <RenderTextField
                control={control}
                name="provider.npi"
                label={'NPI'}
                defaultValue={officeForm?.provider?.npi}
                inputRef={register} />
            <RenderTextField
                control={control}
                name="provider.tax_id"
                label={'Tax ID'}
                defaultValue={officeForm?.provider?.tax_id}
                inputRef={register} />
        </>
    }
    const defaultVersion = ()=>{
        return <>
            <RenderNonEditableField
                control={control}
                name="network"
                label={'Network'}
                value={officeForm?.network}
                inputRef={register} />
            <RenderNonEditableField
                control={control}
                name="payer_specific_description"
                label={'Network Specific'}
                value={officeForm?.payer_specific_description}
                inputRef={register} />
            <RenderNonEditableField
                control={control}
                name="provider.npi"
                label={'NPI'}
                value={officeForm?.provider?.npi}
                inputRef={register} />
            <RenderNonEditableField
                control={control}
                name="provider.tax_id"
                label={'Tax ID'}
                value={officeForm?.provider?.tax_id}
                inputRef={register} /></>
    }

    return <ContentCard title={'Provider'}>
        <Grid
            container
            direction="column"
        >
            {editableVersion()}
        </Grid>
    </ContentCard>
}