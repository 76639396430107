import {Auth0Provider} from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
export const Auth0WithHistory = ({children})=>{
    const history = useHistory();

    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname);
    };
    return <Auth0Provider
        domain="onederful-dental.auth0.com"
        clientId="PrkwqyNSI8xc5nN27AsHMGSH5E6YNkNr"
        audience={"https://production.onederful.co"}
        scope={"feature:eligibility"}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
    >
        {children}
    </Auth0Provider>
}