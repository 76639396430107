import * as React from 'react'
import PropTypes from 'prop-types';
import {useCombobox} from 'downshift'
import {
    Input,
    IconButton,
    FormLabel,
    FormControl,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Fuse from 'fuse.js'

import {useStyles, comboboxStyles} from './utils'

export function AutocompleteComboBox({
                                         items,
                                         placeholder,
                                         label,
                                         onSelectedItemChange,
                                         onBlur
                                     }) {
    const classes = useStyles()
    const itemToString = (item) => (item ? item.primary : '')
    const [inputItems, setInputItems] = React.useState(items)
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getInputProps,
        getComboboxProps,
    } = useCombobox({
        items: inputItems,
        itemToString,
        onInputValueChange: ({inputValue}) => {

            const options = {
                includeScore: true,
                keys: [
                    {
                        name: 'primary',
                        weight: 0.3
                    },
                    {
                        name: 'onederfulId',
                        weight: 0.7
                    }
                ],
                threshold:0.3
            }
            const filterById = new Fuse(items, options)
            const idFilteredResults = filterById.search(inputValue as string)
            // Fuzzy search this potentially
            setInputItems(
                idFilteredResults.map(i=>i?.item)
            )
        },
        onSelectedItemChange:(d)=>onSelectedItemChange(d),
        onStateChange:(d)=> {
            if(d?.type === '__input_blur__'){
                onBlur(highlightedIndex)
            }
        }
    })
    return (
        <div className={classes.container}>
            <FormLabel {...getLabelProps()} style={{zIndex:100}}>{label}</FormLabel>
            <div className={classes.flexContainer}>
                <div style={comboboxStyles} {...getComboboxProps()}>
                    <Input
                        placeholder={placeholder}
                        {...getInputProps({refKey: 'inputRef'})}
                    />
                    <IconButton
                        color="secondary"
                        className={classes.button}
                        {...getToggleButtonProps()}
                    >
                        <ExpandMoreIcon className={classes.rightIcon} />
                    </IconButton>
                </div>
            </div>
            <List className={classes.root} {...getMenuProps()}>
                {isOpen &&
                    inputItems.map((item, index) => {
                        return (
                            <ListItem
                                style={{cursor: 'pointer'}}
                                key={`${item.primary}-${index}`}
                                className={
                                    index === highlightedIndex ? classes.highlighted : undefined
                                }
                                {...getItemProps({
                                    item,
                                    index,
                                })}
                            >
                                <ListItemText
                                    primary={item.primary}
                                    secondary={item.secondary}
                                />
                            </ListItem>
                        )
                    })}
            </List>
        </div>
    )
}

AutocompleteComboBox.propTypes = {
    items:PropTypes.arrayOf(
        PropTypes.shape({
            primary:PropTypes.string,
            secondary: PropTypes.string
        })
    ),
    placeholder:PropTypes.string,
    label:PropTypes.string,
}
