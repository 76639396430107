import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
        maxHeight: 250,
        overflowY: 'auto',
        position: 'absolute',
        margin: 0,
        borderTop: 0,
        zIndex: 10,
        top:60,
    },
    highlighted: {
        backgroundColor: '#bde4ff',
    },
    button: {
        // margin: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    container:{
        border: 0,
        margin: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
        verticalAlign: 'top',
    },
    flexContainer:{
        display:'flex',
        flexDirection:'row'
    }
}))

export const comboboxStyles = {display: 'inline-block'}
